import React, {useState} from 'react'
import FormInput from '../components/FormInput';
import { Button } from '../components/Button'
import Select from 'react-select';
import AnimatedPage from '../components/AnimatedPage';
import Footer from '../components/Footer';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';

function Contact() {
  
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    content: "Service",
    message: "",
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Name",
      label: "Name*",
      pattern: "^[A-Za-z0-9]{3,}$",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email*",
      required: true,
    },
    {
      id: 3,
      name: "phone",
      type: "phone",
      placeholder: "Phone",
      label: "Phone",
    }
  ];

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault()

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_USER_ID)
      .then((result) => {
        console.log(result.text)
        // window.location.reload()
        setValues({
          name: "",
          email: "",
          phone: "",
          content: "Service",
          message: "",
        })
      }, (error) => {
        console.log(error.text)
      })
  };

  const onChange = (e) => {
    if(e.target) {
      setValues({ ...values, [e.target.name]: e.target.value })
    } else {
      setValues({ ...values, [e.name]: e.value })
    }
  };

  const options = [
    { value: 'Service', label: 'Service' },
    { value: 'Product', label: 'Product' },
    { value: 'Clinic Prefabric Construction', label: 'Clinic Prefabric Construction' }
  ]

  return (
    <AnimatedPage>
      <div className='form-div'>
        <div className='form-background'>
          <div className='form-container'>
            <h1>Contact</h1>
              <img className='form-logo' src='./logo-2.png' alt='Logo' />
            <p>
              Feel free to reach out to us using the contact form on the right, and we will respond to your message as soon as possible.
            </p>
            <p>
              <a href="mailto:info@alwayssmilemedicalservice.com">info@alwayssmilemedicalservice.com</a>
            </p>
          </div>
          <div className='form-container'>
            <form ref={form} onSubmit={handleSubmit}>
              {inputs.map((input) => (
                <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                />
                ))}
                <label>Content</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={options[0]}
                  name='content'
                  isSearchable={false}
                  options={options}
                  onChange={onChange}
                  />
                <label>Message</label>
                <textarea name='message' value={values['message']} onChange={onChange}/>
                <Button type='submit' buttonStyle='btn--primary' onClick={handleSubmit}>Send</Button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </AnimatedPage>
  )
}

export default Contact