import React from 'react'
import './HomeSection.css'
import { Button } from './Button'

function HomeSection() {
  return (
    <div className='home-container'>
        <h1>
          Alwayssmile Medical
        </h1>
        <h2>
          LONDON
        </h2>
        <p>
          We have been providing services in the healthcare sector for 17 years, during which time we have realized numerous projects 
          in Turkey, Africa, the Middle East, and Central Asia. We are excited to share our experiences with you.
        </p>
        <Button buttonStyle='btn--outline' buttonSize='btn--large' >Contact</Button>
        <br />
        <br />
        <br />
    </div>
  )
}

export default HomeSection