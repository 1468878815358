import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import Reference from './pages/Reference';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <AnimatePresence>
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/reference' element={<Reference />}/>
            <Route path='/contact' element={<Contact />}/>
          </Routes>
        </AnimatePresence>
      </Router>
    </>
  );
}

export default App;

// #2B368D lacivert
// #F47E20 turuncu