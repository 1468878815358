import React from 'react'
import HomeSection from '../components/HomeSection'
import AnimatedPage from '../components/AnimatedPage'
import Footer from '../components/Footer'
import Services from '../components/Services'

function Home() {
  
  return (
    <AnimatedPage>
      <HomeSection />
      <Services />
      <Footer />
    </AnimatedPage>
  )
}

export default Home