import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <div>
        <img className='footer-logo' src='./logo-4.png' alt='Logo' />
      </div>
      <div className='footer-website-rights-container'>
        <p className='website-rights'>
          All rights reserved. © Alwayssmile Medical
        </p>
        <p className='website-rights'>
          Alwayssmile Online Ltd.
        </p>
        <p className='website-rights'>
          38 Pickardclose N14 6JG, London
        </p>
        <p>
          <a href="mailto:info@alwayssmilemedicalservice.com">info@alwayssmilemedicalservice.com</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
