import React, { useState } from 'react'
import './Services.css'
import Lightbox from "react-awesome-lightbox"
import "react-awesome-lightbox/build/style.css"
import { useMediaQuery } from 'react-responsive'

function Services() {

    const [serviceModalOpen, setServiceModalOpen] = useState(false)
    const [productModalOpen, setProductModalOpen] = useState(false)
    const [constructionModalOpen, setConstructionModalOpen] = useState(false)
    
    function openServiceModal() { setServiceModalOpen(true) }
    function closeServiceModal() { setServiceModalOpen(false) }
    
    function openProductModal() { setProductModalOpen(true) }
    function closeProductModal() { setProductModalOpen(false) }
    
    function openConstructionModal() { setConstructionModalOpen(true) }
    function closeConstructionModal() { setConstructionModalOpen(false) }

    const isSmallScreen = useMediaQuery({query: '(max-width: 1200px)'})

    const serviceImages = [
        {url:'./services/services-1.jpeg',},
        {url:'./services/services-2.jpeg',},
        {url:'./services/services-3.jpeg',},
        {url:'./services/services-4.jpeg',},
        {url:'./services/services-5.jpeg',},
        {url:'./services/services-6.jpeg',},
        {url:'./services/services-7.jpeg',},
        {url:'./services/services-8.jpeg',},
        {url:'./services/services-9.jpeg',},
        {url:'./services/services-10.jpeg',},
        {url:'./services/services-11.jpeg',},
        {url:'./services/services-12.jpeg',},
        {url:'./services/services-13.jpeg',},
        {url:'./services/services-14.jpeg',},
        {url:'./services/services-15.jpeg',},
        {url:'./services/services-16.jpeg',},
        {url:'./services/services-17.jpeg',},
        {url:'./services/services-18.jpeg',}
    ]

    const productImages = [
        {url:'./products/product-1.jpeg',},
        {url:'./products/product-2.jpeg',},
        {url:'./products/product-3.jpeg',},
        {url:'./products/product-4.jpeg',},
        {url:'./products/product-5.jpeg',},
        {url:'./products/product-6.jpeg',},
        {url:'./products/product-7.jpeg',},
        {url:'./products/product-8.jpeg',},
        {url:'./products/product-9.jpeg',},
        {url:'./products/product-10.jpeg',},
        {url:'./products/product-11.jpeg',},
        {url:'./products/product-12.jpeg',},
        {url:'./products/product-13.jpeg',},
        {url:'./products/product-14.jpeg',},
        {url:'./products/product-15.jpeg',},
        {url:'./products/product-16.jpeg',},
        {url:'./products/product-17.jpeg',},
        {url:'./products/product-18.jpeg',},
        {url:'./products/product-19.jpeg',},
        {url:'./products/product-20.jpeg',},
        {url:'./products/product-21.jpeg',},
        {url:'./products/product-22.jpeg',},
        {url:'./products/product-23.jpeg',},
        {url:'./products/product-24.jpeg',},
        {url:'./products/product-25.jpeg',},
        {url:'./products/product-26.jpeg',}
    ]

    const constructionImages = [
        {url:'./construction/adana-1.jpeg',},
        {url:'./construction/adana-2.jpeg',},
        {url:'./construction/adana-3.jpeg',},
        {url:'./construction/adana-4.jpeg',},
        {url:'./construction/adana-5.jpeg',},
        {url:'./construction/erbil-1.jpeg',},
        {url:'./construction/erbil-2.jpeg',},
        {url:'./construction/erbil-3.jpeg',},
        {url:'./construction/erbil-4.jpeg',},
        {url:'./construction/erbil-5.jpeg',},
        {url:'./construction/erbil-6.jpeg',},
        // {url:'./construction/erbil-7.jpeg',},
        {url:'./construction/erbil-8.jpeg',},
        {url:'./construction/erbil-9.jpeg',},
        {url:'./construction/erbil-10.jpeg',},
        {url:'./construction/erbil-11.jpeg',},
        {url:'./construction/erbil-12.jpeg',},
        {url:'./construction/solen-1.jpeg',},
        {url:'./construction/solen-2.jpeg',},
        {url:'./construction/solen-3.jpeg',},
        {url:'./construction/solen-4.jpeg',},
        {url:'./construction/solen-5.jpeg',},
        {url:'./construction/solen-6.jpeg',},
        {url:'./construction/solen-7.jpeg',},
        {url:'./construction/solen-8.jpeg',},
        {url:'./construction/solen-9.jpeg',}
    ]
    
    // eslint-disable-next-line
    const serviceText = "By providing the most suitable products and services tailored to the needs of our hospitals, we not only elevate the quality of the hospitals \
        but also contribute maximally to the development of the health sector. In addition to our sales, we also offer annual maintenance services for all medical devices \
        in hospitals. We examine your inventories and provide you with proposals accordingly. This comprehensive service ensures your equipment remains in optimal condition, \
        reducing unexpected downtime and increasing patient safety."

    // eslint-disable-next-line
    const productText = "Our London-based medical device sales department meticulously inspects all of our products to provide the highest quality service. Our devices \
        undergo a thorough evaluation process to meet your requirements. Furthermore, we provide support even after the purchase process. Each of our devices comes with a service \
        warranty when necessary. This ensures that our customers can choose us with confidence, knowing they will always receive the highest quality service."
    
    // eslint-disable-next-line
    const constructionText = "Clinic prefabric has been working to offer you a suitable place to spend your happily times in its widespread product range since 1987. \
        Clinic prefabric has so far been exported to Georgia, Iraq, Ethiopia, Turkmenistan, Libya, Romania, Russia, Cyprus, Switzerland, etc. \
        It has an experienced team that has realized and successfully delivered projects in many countries. Our company's productions include detached \
        and multi-storey prefabricated houses, schools, hospitals, health centers, steel construction structures, warehouses, and factory buildings."

    return (
        <div className='services-container'>
            <ul className='services-list'>
                <li className='services-element'>
                    <img src='./services/services-9.jpeg' alt='Services' className='services-image' onClick={openServiceModal} />
                    <div className='services-text-container'>
                        <h1 className='services-header'>Services</h1>
                        <p className='services-text'>{serviceText}</p>
                    </div>
                    {serviceModalOpen && <Lightbox images={serviceImages} onClose={closeServiceModal} buttonAlign='center' />}
                </li>
                {isSmallScreen && 
                <li className='services-element'>
                    <img src='./products/product-1.jpeg' alt='Products' className='services-image' onClick={openProductModal} />
                    <div className='services-text-container'>
                        <h1 className='services-header'>Products</h1>
                        <p className='services-text'>{productText}</p>
                    </div>
                    {productModalOpen && <Lightbox images={productImages} onClose={closeProductModal} buttonAlign='center' />}
                </li>
                }
                {!isSmallScreen && 
                <li className='services-element'>
                    <div className='services-text-container'>
                        <h1 className='services-header'>Products</h1>
                        <p className='services-text'>{productText}</p>
                    </div>
                    <img src='./products/product-1.jpeg' alt='Products' className='services-image' onClick={openProductModal} />
                    {productModalOpen && <Lightbox images={productImages} onClose={closeProductModal} buttonAlign='center' />}
                </li>
                }
                <li className='services-element'>
                    <img src='./construction/adana-1.jpeg' alt='Construction' className='services-image' onClick={openConstructionModal} />
                    <div className='services-text-container'>
                        <h1 className='services-header'>Clinic Prefabric Construction</h1>
                        <p className='services-text'>{constructionText}</p>
                    </div>
                    {constructionModalOpen && <Lightbox images={constructionImages} onClose={closeConstructionModal} buttonAlign='center' />}
                </li>
            </ul>
        </div>
    )
}

export default Services