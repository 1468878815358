import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Navbar.css'

function Navbar() {

  const [click, setClick] = useState(false)
  
  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const location = useLocation()

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
            <Link to='/' onClick={closeMobileMenu}>
              <img className='navbar-logo' src='./logo-3.png' alt='Logo' />
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link to='/' className={location.pathname === '/' ? 'nav-links-selected' : 'nav-links'} onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/about' className={location.pathname === '/about' ? 'nav-links-selected' : 'nav-links'} onClick={closeMobileMenu}>
                  About Us
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/reference' className={location.pathname === '/reference' ? 'nav-links-selected' : 'nav-links'} onClick={closeMobileMenu}>
                  Reference
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/contact' className={location.pathname === '/contact' ? 'nav-links-selected' : 'nav-links'} onClick={closeMobileMenu}>
                  Contact
                </Link>
              </li>
            </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar