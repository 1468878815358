import React from 'react'
import './Reference.css'
import AnimatedPage from '../components/AnimatedPage'
import Footer from '../components/Footer'

function Reference() {

  const referenceList = ["Aliağa Public Hospital", "Akhisar Private Hospital", "Aşkale Public Hospital", "Atatürk University Hospital", "Bayburt Public Hospital",
    "Bursa City Hospital", "Celal Bayar University Hospital", "Çad Public Hospital", "Çekirge Public Hospital", "Doğuş Hospital", "Dörtçelik Children's Hospital"]

  const referenceList2 = ["Erzurum City Hospital", "Gemlik Public Hospital", "Gürsü Public Hospital", "Hınıs Public Hospital", "Horosan Public Hospital", "İspir Public Hospital", 
    "İznik Public Hospital", "Karaçoban Public Hospital", "Mareşal Çakmak Public Hospital", "Medikabil Hospital", "Narman Public Hospital"]

  const referenceList3 = ["Oltu Public Hospital", "Olur Public Hospital", "Pasinler Public Hospital", "Pazaryolu Public Hospital", "Şenkaya Public Hospital", 
    "Tekman Public Hospital", "Torbalı Public Hospital", "Tortum Public Hospital", "Uludağ University Hospital"]

  // eslint-disable-next-line
  const referenceText = "Our collaborations with our clients make us proud. With our solutions in the healthcare sector, we offer innovative and customized services \
    to our clients. Below, you can find some examples of our clients in the healthcare industry."

  return (
    <AnimatedPage>
      <div className='reference-background'>
        <div className='reference-container'>
          <h1>Reference</h1>
          <p>{referenceText}</p>
          <div className='list-container'>
            <ul>
              {referenceList.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
            <ul>
              {referenceList2.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
            <ul>
              {referenceList3.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </AnimatedPage>
  )
}

export default Reference
